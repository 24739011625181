import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type AuthState = {
    token: string | null
}

const slice = createSlice({
    name: 'auth',
    initialState: { token: null } as AuthState,
    reducers: {
        setCredentials: (
            state,
            { payload: { access_token } }: PayloadAction<{ access_token: string }>
        ) => {
            state.token = access_token
        },
        clearAuthState: (
            state,
        ) => {
            state.token = null
        },
    },
})

export const { setCredentials, clearAuthState } = slice.actions

export default slice.reducer