import {Box, Button, CircularProgress, Container, Typography} from "@mui/material";
import AuthWrapper from "../AuthWrapper";
import React, {useRef} from "react";
import CountdownButton from "./CountdownButton";
import {useGetCompletedQuizQuery, useGetListQuery} from "../quizApi";
import ErrorIcon from '@mui/icons-material/Error';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {clearAuthState} from "../../app/slices/authSlice";

export default function WelcomeView() {
    const { data: resultsData } =
        useGetListQuery();

    const timestampRef = useRef(Date.now()).current;

    const { data: completedQuizData, isLoading: completedQuizDataLoading } =
        useGetCompletedQuizQuery({sessionId: timestampRef});

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const logout = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(clearAuthState())
        navigate('/');
        window.location.reload();
    }

    if (completedQuizDataLoading) {
        return (
            <AuthWrapper>
                <Container component="main" maxWidth="sm" sx={{ p: 2, backgroundColor: '#fefefe', borderRadius: 2, mt: 6 }}>
                    <CircularProgress />
                </Container>
            </AuthWrapper>
        )
    }

    if(completedQuizData && completedQuizData[0] && completedQuizData[0].answeredAt) {
        return (
            <AuthWrapper>
                <Container component="main" maxWidth="sm" sx={{ p: 2, backgroundColor: '#fefefe', borderRadius: 2, mt: 6 }}>
                    <Box sx={{ mt: 1, textAlign: "center"}} >
                        <Typography variant="h4" component="h1" gutterBottom>
                            Konkurs IT
                        </Typography>
                        <Typography color="#002984" fontSize="20px" component="span" gutterBottom>
                            Nie można dwa razy przystąpić do konkursu
                        </Typography>
                        <Box component="form" onSubmit={logout} noValidate sx={{ mt: 1 }} >
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Wyloguj się
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </AuthWrapper>
        )
    }

    return (
        <AuthWrapper>
            <Container component="main" maxWidth="sm" sx={{ p: 2, backgroundColor: '#fefefe', borderRadius: 2, mt: 6 }}>
               <>
                    {(resultsData && resultsData[0]) ?
                        <Box sx={{ mt: 1, textAlign: "center"}} >
                            <Typography variant="h4" component="h1" gutterBottom>
                                Konkurs IT
                            </Typography>
                            <Typography component="span" gutterBottom>
                                Klikając poniższy przycisk rozpoczynasz test. <br />(Czas będzie liczony dopiero od kliknięcia tego przycisku)
                            </Typography>
                            <CountdownButton quiz={resultsData[0]} />
                        </Box> :
                        <Typography color="error" sx={{ lineHeight: 1.5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><ErrorIcon color="error" fontSize="large" /><Typography component="span" sx={{ pt: 0.5 }}>Brak zaplanowanych konkursów</Typography></Typography>
                    }
                </>
            </Container>
        </AuthWrapper>
    )
}