import {Box, CircularProgress, Container, Typography} from "@mui/material";
import QuestionElement from "./question";
import React, {useEffect} from "react";
import {useAnswearQuestionMutation, useGetNextQuestionQuery} from "../quizApi";
import {useNavigate} from "react-router-dom";
import AuthWrapper from "../AuthWrapper";

export default function QuestionView() {
    const { data: nextQuestion, refetch, isLoading, error  } = useGetNextQuestionQuery()
    const [answearQuestion] = useAnswearQuestionMutation()

    const navigate = useNavigate();

    useEffect(() => {
        if(nextQuestion === null) {
            navigate('/koniec');
        }
    }, [nextQuestion, navigate])
    const handleSubmit = async (value: string | number) => {
        if(nextQuestion) {
            if(value) {
                await answearQuestion({
                    "quizId": nextQuestion.quizId,
                    "questionId": nextQuestion.id,
                    "answerId": +value
                })
            }
            refetch()
        }
    }

    if (isLoading) {
        return (
            <AuthWrapper>
                <Container component="main" maxWidth="sm" sx={{ p: 2, backgroundColor: '#fefefe', borderRadius: 2, mt: 6 }}>
                    <CircularProgress />
                </Container>
            </AuthWrapper>
        )
    }

    if (error) {
        return (
            <AuthWrapper>
                <Container component="main" maxWidth="sm" sx={{ p: 2, backgroundColor: '#fefefe', borderRadius: 2, mt: 6 }}>
                    <Typography>Wystąpił błąd przy pobieraniu pytania</Typography>
                </Container>
            </AuthWrapper>
        )
    }

    if (!nextQuestion) {
        return null;
    }

    return (
        <AuthWrapper>
            <Container component="main" maxWidth="sm" sx={{ p: 2, backgroundColor: '#fefefe', borderRadius: 2, mt: 6 }}>
                <Box sx={{ mt: 1, textAlign: "center"}} >
                    {nextQuestion && <QuestionElement question={nextQuestion} submitAction={(value) => handleSubmit(value)} />}
                </Box>
            </Container>
        </AuthWrapper>
    )
}