// @ts-nocheck
import {Alert, Box, Button, Container, Snackbar, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useLoginUserMutation} from "../quizApi";
import {LoginPost} from "../../interfaces/LoginPost";
import {useDispatch} from "react-redux";
import {clearAuthState, setCredentials} from "../../app/slices/authSlice";
import PinInput from "react-pin-input";
import React from "react";

export default function LoginView() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [pin, setPin] = React.useState('');

    const [loginUser, {error: loginError}] = useLoginUserMutation();
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(clearAuthState())

        const user = await loginUser({
            pin: pin
        } as LoginPost).unwrap()
        if(user) {
            dispatch(setCredentials(user))
            navigate('/start');
        }
    }

    const onChangePin = (value: string) => {
        setPin(value);
    };

    return (
        <Container component="main" maxWidth="sm" sx={{ p: 2, backgroundColor: '#fefefe', borderRadius: 2, mt: 6 }}>
            <Box sx={{ mt: 1, textAlign: "center"}} >
                <Typography variant="h4" component="h1" gutterBottom>
                    Konkurs Szkolny
                </Typography>
                <Typography component="span" gutterBottom>
                    Zaloguj się danymi podanymi przez prowadzącego
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} >
                    <PinInput
                        length={8}
                        focus
                        type="numeric"
                        onChange={onChangePin}
                    />
                    {loginError && loginError.status === 401 &&
                         <Snackbar
                             anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                             color="red"
                            open={loginError ? true : false}
                            message="Login Error"
                        >
                            <Alert severity="error">Nieprawidłowy PIN</Alert>
                        </Snackbar>
                    }
                    {loginError && loginError.status === 429 &&
                        <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            color="red"
                            open={loginError ? true : false}
                            message="Login Error"
                        >
                            <Alert severity="error">Zbyt wiele prób logowania, odczekaj kilka minut</Alert>
                        </Snackbar>
                    }
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Zaloguj się
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}