import {Box, Button, Container} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {LoginPost} from "../../interfaces/LoginPost";
import {clearAuthState, setCredentials} from "../../app/slices/authSlice";
import {useLoginUserMutation} from "../quizApi";
import {useDispatch} from "react-redux";
import React from "react";
import PinInput from "react-pin-input";

export default function AdminLoginView() {
    const navigate = useNavigate();
    const [loginUser] = useLoginUserMutation();
    const dispatch = useDispatch()
    const [pin, setPin] = React.useState('');

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(clearAuthState())

        const user = await loginUser({
            pin: pin
        } as LoginPost).unwrap()
        if (user) {
            dispatch(setCredentials(user))
            navigate('/wp-content');
        }
    }

    const onChangePin = (value: string) => {
        setPin(value);
    };

    return (
        <Container component="main" maxWidth="sm" sx={{ p: 2, backgroundColor: '#fefefe', borderRadius: 2, mt: 6 }}>
            <Box sx={{ mt: 1, textAlign: "center"}} >
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} >
                    <PinInput
                        length={8}
                        focus
                        type="numeric"
                        onChange={onChangePin}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Zaloguj się
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}