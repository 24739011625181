import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';
import {Navigate} from "react-router-dom";

interface AuthWrapperProps {
    children: React.ReactNode;
}

const AuthWrapper: React.FC<AuthWrapperProps> = (props) => {
    const isLoggedIn = useSelector((state: RootState) => state.auth.token);

    return (
        <div>
            {isLoggedIn ? (
                props.children
            ) : (
                <Navigate replace to="/" />
            )}
        </div>
    );
};

export default AuthWrapper;