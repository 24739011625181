import {Box, Container, TextField, Typography} from "@mui/material";
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {useGetResultsQuery} from "../quizApi";
import AuthWrapper from "../AuthWrapper";
import {Score} from "../../interfaces/Score";

export default function ResultsView() {
    const { data: resultsData, isLoading } =
        useGetResultsQuery();

    const columns: GridColDef[] = [
        { field: 'user', headerName: 'Email', sortable: false, renderCell: (cellValues) => {
                return (
                    <Container sx={{ textAlign: 'left'}}>
                        <Typography>{cellValues.row.user.firstName}</Typography>
                        <Typography>{cellValues.row.user.lastName}</Typography>
                        <Typography>{cellValues.row.user.schoolName}</Typography>
                        <Typography>{cellValues.row.user.class}</Typography>
                    </Container>
                )
            },
            width: 150},
        {
            field: 'score',
            headerName: 'Punkty',
            type: 'number',
            sortable: true,
        },
        {
            field: 'time',
            headerName: 'Czas rozwiązywania (ms)',
            type: 'string',
            sortable: true,
            width: 200,
            valueGetter: (params) => {
                const startDate = new Date(params.row.startedAt).getTime();
                const answerAtDate = new Date(params.row.answerAt).getTime();
                return Math.abs(startDate - answerAtDate);
            }
        },
        {
            field: 'startedAt',
            headerName: 'Czas rozpoczęcia',
            type: 'string',
            sortable: true,
            width: 200,
            valueGetter: (params) =>
                `${new Date(params.row.startedAt).toLocaleDateString('pl-PL', { hour: '2-digit', minute: "2-digit", second: "2-digit" })}`,
        },
        {
            field: 'answerAt',
            headerName: 'Czas zakończenia',
            type: 'string',
            sortable: true,
            width: 200,
            valueGetter: (params) =>
                `${new Date(params.row.answerAt).toLocaleDateString('pl-PL', { hour: '2-digit', minute: "2-digit", second: "2-digit" })}`,
        },
    ];

    const sortResultsData = (resultsData: Score[]) => {
        return [...resultsData].sort((a, b) => {
            if (a.score === b.score) {
                const aTime = (+(new Date(a.answerAt)) - +(new Date(a.startedAt)));
                const bTime = (+(new Date(b.answerAt)) - +(new Date(b.startedAt)));

                return aTime - bTime;
            }
            return b.score - a.score;
        });
    };

    return (
        <AuthWrapper>
            {resultsData && !isLoading && <Container component="main" maxWidth="md" sx={{ p: 2, backgroundColor: '#fefefe', borderRadius: 2, mt: 6 }}>
                <Box sx={{ mt: 1, textAlign: "center"}} >
                    {resultsData && <DataGrid
                        getRowId={(row: any) =>  row.user.id}
                        autoHeight={true}
                        rows={sortResultsData(resultsData)}
                        columns={columns}
                        rowHeight={100}
                        pageSize={99}
                        rowsPerPageOptions={[99]}
                        disableColumnFilter
                        disableDensitySelector
                        disableColumnMenu
                        disableColumnSelector
                    />}

                </Box>
            </Container>}
        </AuthWrapper>
    )
}