import React, { useState, useEffect } from 'react';
import {Alert, Box, Button, Snackbar, Typography} from "@mui/material";
import {useStartQuizMutation} from "../quizApi";
import {useNavigate} from "react-router-dom";
import {Quiz} from "../../interfaces/Quiz";
import InfoIcon from '@mui/icons-material/Info';

const CountdownButton = ({quiz}: {quiz: Quiz}) => {
    const navigate = useNavigate();
    const [blockStart, setBlockStart] = useState(true)

    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    const [startQuiz, {error}] = useStartQuizMutation();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await startQuiz()

        navigate('/pytania');
    }

    useEffect(() => {
        const targetDate = new Date(quiz.startAt);
        const intervalId = setInterval(() => {
            const currentDate = new Date();
            const difference = targetDate.getTime() - currentDate.getTime();

            if (difference >= 0) {
                setTimeLeft({
                    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                    minutes: Math.floor((difference / 1000 / 60) % 60),
                    seconds: Math.floor((difference / 1000) % 60),
                });
            } else {
                setBlockStart(false)
                clearInterval(intervalId);
            }
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <>
            {blockStart && <Typography color="#2196f3" sx={{ mt: 1, mb: -2, lineHeight: 1.5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <InfoIcon fontSize="large"/>
                <Typography component="span" sx={{ pt: 0.5 }}>Do rozpoczęcia konkursu: {timeLeft.days} dni {timeLeft.hours} godzin {timeLeft.minutes} minut {timeLeft.seconds} sekund</Typography>
            </Typography>}
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} >
                <Button
                    disabled={blockStart}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Rozpocznij test
                </Button>
            </Box>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                color="red"
                open={error ? true : false}
                message="Błąd rozpoczęcia konkursu"
            >
                <Alert severity="error">Błąd rozpoczęcia konkursu</Alert>
            </Snackbar>
        </>
    );
};

export default CountdownButton;