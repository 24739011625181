import React from "react"

import {Navigate, useRoutes} from "react-router-dom"
import LoginView from "../views/LoginView";
import WelcomeView from "../views/WelcomeView";
import ThankYouView from "../views/ThankYouView";
import AdminLoginView from "../views/AdminLoginView";
import ResultsView from "../views/ResultsView";
import QuestionView from "../views/QuestionView";

export default function DashboardRoutes() {
    return useRoutes([
        {
            path: "/",
            children: [
                {
                    path: "/",
                    element: <LoginView />,
                },
                {
                    path: "/start",
                    element: <WelcomeView />,
                },
                {
                    path: "/pytania",
                    element: <QuestionView />,
                },
                {
                    path: "/koniec",
                    element: <ThankYouView />,
                },
                {
                    path: "/wp-admin",
                    element: <AdminLoginView />,
                }
                ,                {
                    path: "/wp-content",
                    element: <ResultsView />,
                },
                {
                    path: "*",
                    element: <Navigate replace to="/" />,
                },
            ],
        },
    ])
}
