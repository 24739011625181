import {Box, Button, Container, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import React from "react";
import {clearAuthState} from "../../app/slices/authSlice";
import {useDispatch} from "react-redux";
import AuthWrapper from "../AuthWrapper";
 const ThankYouView: React.FC = () => {
     const dispatch = useDispatch()
    const navigate = useNavigate();
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(clearAuthState())
        navigate('/');
        window.location.reload();
    }


    return (
        <AuthWrapper>
            <Container component="main" maxWidth="sm" sx={{ p: 2, backgroundColor: '#fefefe', borderRadius: 2, mt: 6 }}>
                <Box sx={{ mt: 1, textAlign: "center"}} >
                    <Typography variant="h4" component="h1" gutterBottom>
                        Koniec
                    </Typography>
                    <Typography component="span" gutterBottom>
                        Twoje odpowiedzi zostały wysłane. Możesz już zamknąć tę stronę. <br />
                        Dziękujemy za udział.
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} >
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Wyloguj się
                        </Button>
                    </Box>
                </Box>
            </Container>
        </AuthWrapper>
    )
}

export default ThankYouView