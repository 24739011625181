import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {LoginPost, LoginPostResponse} from "../interfaces/LoginPost";
import type { RootState } from '../app/store'
import {StartQuizResponse} from "../interfaces/StartQuizResponse";
import {Score} from "../interfaces/Score";
import {Quiz} from "../interfaces/Quiz";
import {CompletedQuiz} from "../interfaces/CompletedQuiz";
import {AnswearToPost, Question} from "../interfaces/Question";
export const quizApi = createApi({
    reducerPath: "aleWebApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `https://api-konkurs.effeszkola.pl`,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token

            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }),
    endpoints: (build) => ({
        loginUser: build.mutation<LoginPostResponse, LoginPost>({
            query: (body) => {
                return {
                    url: `/auth/login`,
                    method: "POST",
                    body: body,
                };
            },
        }),
        startQuiz: build.mutation<StartQuizResponse, void>({
            query: () => {
                return {
                    url: `/quiz-instance/start`,
                    method: "POST",
                    body: {
                        "quizId": 1
                    },
                };
            },
        }),
        getNextQuestion: build.query<Question, void>({
            query: () => `/quiz-instance/next-question?quizId=1`,
            transformResponse: (response: Question) => {
                if(!response) {
                    return response
                }
                const shuffledAnswers = [...response.answers];
                shuffledAnswers.sort(() => Math.random() - 0.5);
                return {
                    ...response,
                    answers: shuffledAnswers,
                };
            },
        }),
        answearQuestion: build.mutation<void, AnswearToPost>({
            query: (body) => {
                return {
                    url: `/quiz-instance/answer-question`,
                    method: "POST",
                    body: body,
                };
            },
        }),
        getResults: build.query<Score[], void>({
            query: () => `quiz/1/scores`,
        }),
        getList: build.query<Quiz[], void>({
            query: () => `quiz`,
        }),
        getCompletedQuiz: build.query<CompletedQuiz[], {sessionId: number}>({
            query: () => `quiz-instance`,
        }),
    }),
});

export const { useLoginUserMutation, useStartQuizMutation, useAnswearQuestionMutation, useGetResultsQuery, useGetListQuery, useGetNextQuestionQuery, useGetCompletedQuizQuery } = quizApi;
