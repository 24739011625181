import {Box, Button, FormControlLabel, Radio, RadioGroup, Typography} from "@mui/material";
import {Question} from "../../interfaces/Question";
import React, {useEffect} from "react";
import AuthWrapper from "../AuthWrapper";

const QuestionElement = ({
    question,
    submitAction
}: {
    question: Question;
    submitAction: (value: number | string) => void
}) => {
    const [value, setValue] = React.useState('');

    const [countdown, setCountdown] = React.useState(30);

    useEffect(() => {
        // Dekrementuj licznik co sekundę
        const intervalId = setInterval(() => {
            setCountdown(countdown => countdown - 1);
        }, 1000);

        // Zatrzymaj interwał po zakończeniu odliczania
        if (countdown === 0) {
            clearInterval(intervalId);
            submitQuestion();
        }

        // Zwróć funkcję do wyczyszczenia interwału po odmontowaniu komponentu
        return () => clearInterval(intervalId);
    }, [countdown]);

    useEffect(() => {
        setValue('')
        setCountdown(30)
    }, [question])
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const submitQuestion = () => {
        submitAction(value);
    }

    return (
        <AuthWrapper>
            <Typography variant="h4" component="h1" gutterBottom>
                {question.text}
            </Typography>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={value}
                onChange={handleRadioChange}
                name="radio-buttons-group"
            >
                {question.answers.map((answear) => (
                    <FormControlLabel sx={{textAlign: 'left'}} key={answear.id} value={answear.id} control={<Radio />} label={answear.text} />
                ))}
            </RadioGroup>
            <Box component="form" onSubmit={(e: React.FormEvent<HTMLFormElement>) => {e.preventDefault(); if(value) {submitQuestion()}}} noValidate sx={{ mt: 1 }} >
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Następne pytanie
                </Button>
            </Box>
            <Typography>Pozostało {countdown} sekund</Typography>
        </AuthWrapper>
    )
}
export default QuestionElement